import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
// import dealProductLinkMixin from '@/mixins/dealProductLink';

const customParseFormat = require('dayjs/plugin/customParseFormat');

dayjs.extend(customParseFormat);

export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    isBig: {
      type: Boolean,
      default: false,
    },
  },
  // mixins: [dealProductLinkMixin],
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      isRTLPage: 'GET_IS_RLT_PAGE',
      isLanding: 'GET_IS_LANDING_PAGE',
      device: 'GET_DEVICE',
      marketerId: 'GET_MARKETER_ID',
      isMemberLogin: 'IS_AUTHORIZED',
      memberDiscountPercent: 'GET_DISCOUNT_PERCENT',
      isOdysseySite: 'GET_ODYSSEY_AGENT_STATE',
      isFCLAgent: 'IS_FCL_ODYSSEY_AGENT_CODE',
      landingInfo: 'GET_LANDING_INFO',
    }),
    item() {
      const valueArray = this.items[1];
      return (valueArray) ? valueArray[0] : null;
    },
    moreItems() {
      const valueArray = this.items[1];
      return (valueArray && valueArray.length > 1) ? valueArray.slice(1) : [];
    },
    isBadge() {
      return this.item?.badgeColor && this.item?.badgeText;
    },
    isTimer() {
      return this.item?.specialDiscount && this.item?.specialDiscount.endDate && new Date(this.item?.specialDiscount.endDate) > new Date();
    },
    discountPercent() {
      return (this.item?.specialDiscount?.discountPercent && new Date(this.item?.specialDiscount?.endDate) > new Date()) ? this.item?.specialDiscount?.discountPercent : this.item?.appliedDiscountPercent;
    },
    imageUrl() {
      const { item } = this;
      let url = '';

      if (!item) return '';
      url = (item.imageUrl !== 'null') ? url = item.imageUrl : null;
      url = url || item?.extendedProperties?.hotelInfo?.mainImageUrls?.[0] || item?.extendedProperties?.hotelConversion?.imageUrl || 'assets/img/lesiurpackages_hotelsimg5.jpg';
      return url;
    },
    productPageLink() {
      const { marketerId, item, imageUrl, landingInfo } = this;
      const utmSourceParam = landingInfo?.utm_source ? `&utm_source=${landingInfo.utm_source}` : '';
      let url = item.productPageUrl;
      if (url.includes('&lang=')) {
        url.replace(/&lang=\w+/, `&lang=${this.lang}`);
      } else {
        url += `&lang=${this.lang}`;
      }
      url += `&dealId=${item.id}&mainimg=${imageUrl}&dealCategoryId=${this.items[0]}${utmSourceParam}`;
      if (marketerId && marketerId !== '') url = `${url}&marketerId=${marketerId}`;

      return url;
    },
    discountedPrice() {
      const { totalAfterDiscount, totalPrice } = this.item;
      if (this.isOdysseySite && !this.isFCLAgent) return Math.round(totalPrice);
      if (!this.isMemberLogin) return Math.round(totalAfterDiscount);

      const isAtlantis = this.item.atlantisDiscounted;
      const isCreditCardRemarkHotel = this.item.cardRemarkDiscounted;
      const { memberDiscountPercent } = this;
      const price = (isAtlantis || isCreditCardRemarkHotel) ? totalAfterDiscount : totalAfterDiscount * (1 - memberDiscountPercent / 100);
      return Math.round(price);
    },
    remark() {
      return this.supplierName === 'ATLANTIS' ? this.item?.roomRemark?.trim() || '' : '';
    },
    supplierName() {
      const { item } = this;
      const { extendedProperties } = item;
      return extendedProperties.sourceName;
    },
    commission() {
      const { isFCLAgent, item } = this;
      const { extendedProperties } = item;
      if (extendedProperties.sourceName === 'ATLANTIS') return 5;
      let { commission } = extendedProperties.hotelConversion;
      if (!isFCLAgent) {
        commission = Number(commission) - 5;
      }
      return commission;
    },
    isOfirQuery() {
      return this.$route.query.dealOrderTesting === 'true';
    },
    isMobile() {
      return this.device === 'mobile';
    },
  },
  watch: {
    lang() {
      // console.log(this.lang);
    },
  },
  data() {
    return {
      mainProps: {
        blankColor: '#bbb',
        class: 'card-img-top img-fluid',
      },
      isSalesDealExpired: false,
    };
  },
  methods: {
    changeDateFormat(str) {
      return str.split('T')[0].split('-').reverse().join('/');
    },
    calcNight(day1, day2) {
      const a = dayjs(this.changeDateFormat(day1), 'DD/MM/YY');
      const b = dayjs(this.changeDateFormat(day2), 'DD/MM/YY');
      return a.diff(b, 'day');
    },
    finishCountdown() {
      this.isSalesDealExpired = true;
    },
  },
};
