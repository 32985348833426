<template>
  <div class="col-lg-4 col-md-6 col-sm-6 packages_boby_col" :class="isMobile && 'p-0'">
    <div class="packages_box">
      <div class="imgbox">
        <img :src="imageUrl" :alt="item.hotelName"
          v-bind="mainProps"
          onerror="this.src='assets/img/lesiurpackages_hotelsimg6.jpg'"
        >
      </div>
      <div class="packages_inner">
        <div class="packages_inner_top">
          <div class="discount_box d-flex" v-if="(isOdysseySite && isFCLAgent && discountPercent > 0 && !isBadge) || (!isOdysseySite && discountPercent > 0 && !isBadge)">
            <div class="d-block m-auto"><strong>{{discountPercent + memberDiscountPercent}}%</strong> {{ $t("home.discount") }}</div>
          </div>
          <!-- <MoreDealCollapse :index="item.id" :moreItems="moreItems" class="collapse-button" v-if="moreItems && moreItems.length > 0"/> -->
          <!-- <div class="adates">Additional Dates</div> -->
        </div>
        <div class="packages_inner_middel">
          <div class="packages_inner_middel_top">
            <h3 v-if="isOfirQuery">{{item.extendedProperties.sourceName}} - {{item.priority}}</h3>
            <h2>{{ item.hotelName }}, {{ item.extendedProperties.destination.name}}</h2>
            <p><img src="assets/img/theme1/clender.png" alt="calendar"> <span dir="ltr">{{ changeDateFormat(item.checkinDate) }} - {{ changeDateFormat(item.checkoutDate) }}</span></p>

            <p class="remark" v-if="remark"><i class="far fa-comment-dots"></i>{{ remark }}</p>

            <a v-if="isLanding" :href="productPageLink"><div class="place btn-primary">{{ $t("home.domestic-order-button") }}</div></a>
            <router-link v-else :to="productPageLink"><button class="btntext">{{ $t("home.domestic-order-button") }}</button></router-link>
          </div>
          <div class="packages_inner_middel_bottom d-flex justify-content-between align-items-end">
            <div class="ulist">
              <ul>
                <li><img src="assets/img/theme1/check.png" alt="check">{{$t("home.what-including-in-price")}}</li>
                <li><img src="assets/img/theme1/check.png" alt="check">{{$t("home.total-night")}}: {{ calcNight(item.checkoutDate, item.checkinDate) }} {{ (calcNight(item.checkoutDate, item.checkinDate) > 1) ? $t("home.nights") : $t("home.night") }}</li>
                <li><img src="assets/img/theme1/check.png" alt="check">{{ item.priceRemark }}</li>
              </ul>
            </div>
            <div class="price">
              <h3><span class="currency-symbol">₪</span><strong>{{discountedPrice}}</strong><br>{{$t("home.total-price-for-the-package")}}</h3>
              <h3 v-if="isOdysseySite && !isFCLAgent && (!isBig || device==='mobile')">{{$t("home.this-order-has")}} <span>{{`${commission}%`}}</span></h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import { BBadge } from 'bootstrap-vue';
import TemplateLesiurtabItem from './TemplateLesiurtabItem';

export default {
  name: 'LesiurtabItemTheme1',
  mixins: [TemplateLesiurtabItem],
  components: {
    // BBadge,
    // HotOfferIcon: () => import('@/components/atoms/HotOfferIcon'),
    // countdown: () => import('@/components/atoms/CountDown'),
    // MoreDealCollapse: () => import('@/components/atoms/moreDealCollapse/MoreDealCollapseTheme1'),
  },
};
</script>
<style scoped>
  .packages_boby_col .packages_box {
      width: 100%;
      overflow: hidden;
      height: 330px;
      position: relative;
      background-position: center;
      background-size: cover;
      border-radius: 10px;
      margin-bottom: 24px;
  }
  .packages_boby_col .packages_box .imgbox{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    overflow: hidden;
  }
  .packages_boby_col .packages_box .imgbox img{
    width:100%;
    height: 100%;
    object-fit:cover;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    }
  .packages_boby_col .packages_box:hover .imgbox img{
      -webkit-transform: scale(1.1);
      -moz-transform: scale(1.1);
      -o-transform: scale(1.1);
      transform: scale(1.1);
      -webkit-transition: 0.5s;
      -moz-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s;
  }
  .packages_boby_col .packages_box:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      height: 50%;
      background: rgb(0,26,45);
      background: linear-gradient(360deg, rgba(0,26,45,1) 0%, rgba(253,187,45,0) 100%);
      z-index: 1;
      width: 100%;
      -webkit-transition: 0.5s;
      -moz-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s;
  }
  .packages_boby_col .packages_box:hover:after{
      height: 100%;
  }
  .packages_boby_col .packages_box  .packages_inner {
      position: absolute;
      z-index: 2;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      overflow: hidden;
  }
  .packages_boby_col .packages_box  .packages_inner  .packages_inner_middel{ position:absolute; bottom:0; box-sizing: border-box; padding: 18px; z-index: 4; width: 100%; bottom: 0;}
  .packages_box  .packages_inner  .packages_inner_middel .packages_inner_middel_top {
      margin-bottom: 54px;
  }
  .packages_box  .packages_inner  .packages_inner_middel .packages_inner_middel_top h2 {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #fff;
}
.packages_box  .packages_inner  .packages_inner_middel .packages_inner_middel_top p {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 5px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
}
.packages_box  .packages_inner  .packages_inner_middel .packages_inner_middel_top p img{ margin-left:10px; }
.packages_box  .packages_inner  .packages_inner_middel .packages_inner_middel_top button.btntext {
    background-color: #23BEDC;
    border: none;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    padding: 9px 18px;
    border-radius: 4px;
    cursor: unset;
}
.packages_box  .packages_inner  .packages_inner_middel .packages_inner_middel_bottom .ulist ul{ margin:0; padding:0; list-style:none; }
.packages_box  .packages_inner  .packages_inner_middel .packages_inner_middel_bottom .ulist ul li{ font-size:12px; color:#fff; font-weight:400; margin-bottom:2px; }
.packages_box  .packages_inner  .packages_inner_middel .packages_inner_middel_bottom .ulist ul li img {
    min-width: 11px;
    max-width: 11px;
    margin-left: 5px;
}
.packages_box  .packages_inner  .packages_inner_middel .packages_inner_middel_bottom .price{ min-width:30%; max-width:30%; }
.packages_box  .packages_inner  .packages_inner_middel .packages_inner_middel_bottom .price h3 {
    font-size: 12px;
    font-weight: 400;
    color: #fff;
    margin: 0;
}
.packages_box  .packages_inner  .packages_inner_middel .packages_inner_middel_bottom .price h3 strong{ font-size:26px; font-weight:700; }
.packages_boby_col .packages_box  .packages_inner .discount_box {
    width: 166px;
    display: flex;
    background-color: #1993C8;
    height: 166px;
    position: absolute;
    left: -50px;
    top: -50px;
    border-radius: 50%;
    color: #fff;
    text-align: right;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    padding: 30px 29px 0 70px;
    font-size: 14px;
}
.packages_boby_col .packages_box  .packages_inner .discount_box strong {
    display: block;
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
}
.packages_boby_col .packages_box  .packages_inner .discount_box:after {
    content: "";
    position: absolute;
    width: 172px;
    height: 172px;
    background-color: rgb(255 255 255 / 61%);
    left: -1px;
    bottom: -4px;
    z-index: -1;
    border-radius: 50%;
}
.packages_box  .packages_inner .collapse-button {
    font-size: 12px;
    text-transform: uppercase;
    background-color: #FF8B01;
    position: absolute;
    right: 0;
    color: #fff;
    font-weight: 600;
    padding: 7px 14px;
    top: 10px;
    border-radius: 6px 0px 0px 6px;
}
.packages_box .packages_inner .collapse-button .btn-success,
.packages_box .packages_inner .collapse-button .btn-success:not(:disabled):not(.disabled):active,
.packages_box .packages_inner .collapse-button .btn-success .btn-success:focus
{
    background-color: unset;
    border: unset;
}
.packages_box .packages_inner .collapse-button .btn-success:focus{
    box-shadow: 0 0 0 0.2rem rgb(255 255 255 / 50%);
}
.packages_boby_grid .packages_boby_col:nth-of-type(6n+2) .packages_box{
    height: 540px;
}
.packages_boby_grid .packages_boby_col:nth-of-type(6n+3) .packages_box{
    height: 330px;
}
.packages_boby_grid .packages_boby_col:nth-of-type(6n+4) .packages_box{
    height: 540px;
}
.packages_boby_grid .packages_boby_col:nth-of-type(6n+5) .packages_box{
    height: 540px;
}
.packages_boby_grid .packages_boby_col:nth-of-type(6n+6) .packages_box{
    height: 330px;
}

.packages_inner_middel .remark {
  font-size: 17px;
  color: #FFFFFF;
  direction: rtl;
  line-height: 15px;
}

@media(max-width: 1399px) {
  .packages_tabmenu .nav-tabs .nav-link {
      padding: 12px 8px;
      font-size: 13px;
  }
  .packages_box  .packages_inner  .packages_inner_middel .packages_inner_middel_top h2 {
      font-size: 24px;
  }
  .packages_box  .packages_inner  .packages_inner_middel .packages_inner_middel_top p {
      font-size: 15px;
  }
  .packages_box  .packages_inner  .packages_inner_middel .packages_inner_middel_top p img {
      margin-left: 7px;
      width: 18px;
  }
  .packages_box  .packages_inner  .packages_inner_middel .packages_inner_middel_top button.btntext {
      font-size: 13px;
      padding: 7px 12px;
  }
}

@media (max-width:767.98px){
  .packages_boby_grid .packages_boby_col:nth-of-type(2n) .packages_box {
      height: 420px;
  }
  .packages_box .packages_inner .packages_inner_middel .packages_inner_middel_top button.btntext {
      font-size: 10px;
      padding: 6px 8px;
  }
  .packages_box .packages_inner .packages_inner_middel .packages_inner_middel_top p {
      font-size: 12px;
  }
  .packages_box .packages_inner .packages_inner_middel .packages_inner_middel_top p img {
      margin-left: 6px;
      width: 15px;
  }
  .packages_box .packages_inner .packages_inner_middel .packages_inner_middel_top p {
      font-size: 12px;
      margin-bottom: 5px;
  }
  .packages_box .packages_inner .packages_inner_middel .packages_inner_middel_top h2 {
      font-size: 18px;
      margin-bottom: 2px;
  }
  .packages_boby_col .packages_box .packages_inner .discount_box {
      width: 140px;
      height: 140px;
      left: -39px;
      top: -53px;
      font-size: 12px;
  }
  .packages_boby_col .packages_box .packages_inner .discount_box::after {
      width: 146px;
      height: 148px;
  }
  .packages_box .packages_inner .packages_inner_middel .packages_inner_middel_top {
      margin-bottom: 20px;
  }
  .packages_box .packages_inner .packages_inner_middel .packages_inner_middel_bottom .ulist ul li {
      font-size: 9px;
  }
  .packages_box .packages_inner .packages_inner_middel .packages_inner_middel_bottom .ulist ul li img {
      min-width: 8px;
      max-width: 8px;
      margin-left: 3px;
  }
  .packages_box .packages_inner .packages_inner_middel .packages_inner_middel_bottom .price h3 strong {
      font-size: 18px;
  }
  .packages_box .packages_inner .packages_inner_middel .packages_inner_middel_bottom .price h3 {
      font-size: 9px;
  }
  .packages_boby_col .packages_box .packages_inner .adates {
      font-size: 10px;
  }
}
</style>
